// src/components/Header.js
import React from "react";

const Header = () => {
  return (
    <>
      <div className="container-fluid bg-black p-7">
        <div className="container">
          <div className="row">
            <div className="col-md-12 subHeader">
              <a href="https://t.me/pump_ninja" target="_blank">
                [Telegram]
              </a>{" "}
              <a href="https://x.com/pump_ninja" target="_blank">
                [Twitter]
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="row">
              <div className="col-md-6 textLogo textRight">
                <span className="green-color bebas-neue">PUMP.</span>
                <span className="white-color maxWidth130 bebas-neue">
                  NINJA
                </span>
              </div>
              <div className="col-md-6">
                <img
                  src="/assets/img/logo-pump-ninja.png" // Llamada al logotipo desde public/assets/img
                  alt="PUMP.NINJA Logo"
                  className="maxWidth130"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3 montserrat-500">
                <p>🔮 Coming Soon to PUMP.NINJA 🔮</p>
                <p>
                  🚀 What’s coming to PUMP.NINJA: Check out the exciting new
                  tools we’re building for you:
                </p>

                <ul>
                  <li>🌐 Real-Time Token Alerts</li>
                  <li>📈 Advanced Pump Analysis</li>
                  <li>📬 Telegram Bot Integration</li>
                  <li>🛠️ Personalized Dashboard</li>
                  <li>💡 Trading Strategy Simulator</li>
                  <li>📊 Token Comparison Tool</li>
                </ul>

                <p>
                  Stay tuned and be the first to benefit from these awesome
                  features at PUMP.NINJA 💥
                </p>
                <p>Join US</p>
                <p className="text-center mb-3">
                  <a href="https://t.me/pump_ninja" target="_blank">
                    [Telegram]
                  </a>{" "}
                  <a href="https://x.com/pump_ninja" target="_blank">
                    [Twitter]
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
