import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";

import "./styles.css";

export default function App() {
  return (
    <>
      <Header />
    </>
  );
}
